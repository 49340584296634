import Vue from 'vue';
import VueRouter from 'vue-router';
import SwapIndex from '../views/swap/Index.vue';

import Swap from '../views/swap/Swap.vue';
import SwapV2 from '../views/swapV2/swapV2.vue';
import FlashExchange from '../views/swap/FlashExchange.vue';
import VisualChart from '../views/swap/VisualChart.vue';
import Home from '../views/Home.vue';
import LiquidityIndex from '../views/liquidity/Index.vue';
import Liquidity from '../views/liquidity/Liquidity.vue';
import importMining from '../views/liquidity/importMining.vue';
import Remove from '../views/liquidity/Remove.vue';
import boardOfDirectors from '../views/boardOfDirectors/Index.vue';
import NoticeIndex from '../views/notice/Index.vue';
import Notice from '../views/notice/notice.vue';
import Invitation from '../views/invitation/Index.vue';
import Index from '../views/index/index.vue';
import Mining from '../views/index/Mining.vue';
import Lianchuang from '../views/index/lianchuang.vue'; 

import EquityPool from '../views/market/EquityPool.vue';
import Congress from '../views/market/Congress.vue';
import Member from '../views/market/Member.vue';
import GdtReward from '../views/market/GdtReward.vue';
import Business from '../views/market/Business.vue';
import Community from '../views/community/index.vue';
import lianchuang from '../views/index/lianchuang.vue'//联创
import vgtLianchuang from '../views/index/vgtLianchuang.vue'
import Mine from '../views/mine/index.vue'
import AssetPack from '../views/mine/assetPack.vue'
import SnowBall from '../views/SnowballProgram/Index.vue'
import snowguan from '../views/SnowballProgram/snowguan/Index.vue'
import ChoBi from '../views/SnowballProgram/chobi/Index.vue'
import ChoBi1 from '../views/SnowballProgram/chobi/chobi.vue'
import TiBi2 from '../views/SnowballProgram/chobi/tibi.vue'
import ZhuangZhan3 from '../views/SnowballProgram/chobi/zhuanzhang.vue'
import historyshoyi from '../views/SnowballProgram/shoyi/historyshoyi.vue'
import bangzhu from '../views/SnowballProgram/bangzhu/Index.vue'
import gongao from '../views/SnowballProgram/gongao/Index.vue'
import xiangqing from '../views/SnowballProgram/gongao/xiangqing.vue'
import shandui from '../views/SnowballProgram/shandui/Index.vue'
import shequ from '../views/SnowballProgram/shequ/Index.vue'
import zhangdan from '../views/SnowballProgram/zhangdan/Index.vue'
import yaoqing from '../views/SnowballProgram/yaoqing/Index.vue'
import zhuzao from '../views/SnowballProgram/zhuzao/Index.vue'
import fankui from '../views/SnowballProgram/fankui/Index.vue'
import fankuiadd from '../views/SnowballProgram/fankui/addfankui.vue'
import fanxiangqin from '../views/SnowballProgram/fankui/xiangqin.vue'
import kuangchi from '../views/SnowballProgram/kuangchi/kuangchi.vue'
import fenxiang918 from '../views/SnowballProgram/kuangchi/fenxiang918.vue'
import konto918 from '../views/SnowballProgram/kuangchi/konto918.vue'
import kuangchiIndex from '../views/SnowballProgram/kuangchi/Index.vue'
import fenxiang from '../views/SnowballProgram/kuangchi/fenxiang.vue'
import Mall from '../views/index/mall.vue'
import footer from '../views/footer/Index.vue'
import earn from '../views/earnmoney/Index.vue'
import iso from '../views/iso/Index.vue'
import recruit from '../views/recruit/Index.vue'
import NFTCenter from '../views/nftCenter/index.vue'

import engbill from '../views/nftCenter/engbill.vue'
import nftbill from '../views/nftCenter/nftbill.vue'

import gamePage from '../views/gamemarket/index.vue'

import transferPage from '../views/SnowballProgram/transfer/transferPage.vue'


// import boardOfDirectors from '../views/boardOfDirectors/Index.vue'
Vue.use(VueRouter);
const routes = [

  {
    path: '/',
    redirect: 'footer/Index',
    component: Home,
    children: [
     
      
     
      {
        path: 'footer',
        name: 'footer',
        component: footer,
        children:[
          {
            path: 'Index',
            name: 'Index',
            component: Index,
          },
          {
            path: 'SwapIndex',
            name: 'SwapIndex',
            component: SwapIndex,
            children: [
              {
                path: 'Swap',
                name: 'Swap',
                component: Swap,
              },
              {
                path: 'SwapV2',
                name: 'SwapV2',
                component: SwapV2,
              },
              {
                path: 'LiquidityIndex',
                name: 'LiquidityIndex',
                component: LiquidityIndex,
              },
              {
                path: 'Liquidity',
                name: 'Liquidity',
                component: Liquidity,
              },
              {
                path: 'importMining',
                name: 'importMining',
                component: importMining,
              },
              {
                path: 'Remove',
                name: 'Remove',
                component: Remove,
              },
             
            ],
          },
          {
            path: 'kuangchiIndex',
            name: 'kuangchiIndex',
            component: kuangchiIndex,
          },
          {
            path: 'kuangchi',
            name: 'kuangchi',
            component: kuangchi,
          },
          {
            path: 'fenxiang',
            name: 'fenxiang',
            component: fenxiang,
          },
          {
            path: 'fenxiang918',
            name: 'fenxiang918',
            component: fenxiang918,
          },
          {
            path: 'konto918',
            name: 'konto918',
            component: konto918,
          },
          {
            path: 'Invitation',
            name: 'Invitation',
            component: Invitation,
          },
          {
            path: 'earn',
            name: 'iso',
            component: earn,
          },
          {
            path: 'iso',
            name: 'earn',
            component: iso,
          },
        ]
      },
    
      {
        path: 'NFTCenter',
        name: 'NFTCenter',
        component: NFTCenter,
      },
      {
        path: 'engbill',
        name: 'engbill',
        component: engbill,
      },
      {
        path: 'nftbill',
        name: 'nftbill',
        component: nftbill,
      },
      {
        path: 'recruit',
        name: 'recruit',
        component: recruit,
      },
      {
        path: 'gongao',
        name: 'gongao',
        component: gongao,
      },
      {
        path: 'xiangqing',
        name: 'xiangqing',
        component: xiangqing,
      },
      {
        path: 'NoticeIndex',
        name: 'NoticeIndex',
        component: NoticeIndex,
      },
      {
        path: 'Notice',
        name: 'Notice',
        component: Notice,
      },
     

      {
        path: 'Mining',
        name: 'Mining',
        component: Mining,
      },
      {
        path: 'Lianchuang',
        name: 'Lianchuang',
        component: Lianchuang,
      },
      {
        path: 'EquityPool',
        name: 'EquityPool',
        component: EquityPool,
      },
      {
        path: 'Congress',
        name: 'Congress',
        component: Congress,
      },
      {
        path: 'Member',
        name: 'Member',
        component: Member,
      },
      {
        path: 'GdtReward',
        name: 'GdtReward',
        component: GdtReward,
      },
      {
        path: 'Business',
        name: 'Business',
        component: Business,
      },
      {
        path: 'Community',
        name: 'Community',
        component: Community,
      },
      {
        path: 'Mall',
        name: 'Mall',
        component: Mall,
      },
      {
        path: '/vgtLianchuang',
        name: 'vgtLianchuang',
        component: vgtLianchuang,
      },
      {
        path: '/Mine',
        name: 'Mine',
        component: Mine,
      },
      {
        path: '/AssetPack',
        name: 'AssetPack',
        component: AssetPack,
      },
      
      {
        path: 'SnowBall',
        name: 'snowguan',
        component: SnowBall,
        children:[
          {
            path: 'snowguan',
            name: 'snowguan',
            component: snowguan,
          },

          {
            path: 'gamePage',
            name: 'gamePage',
            component: gamePage,
          },
          {
            path: 'transferPage',
            name: 'transferPage',
            component: transferPage,
          },
          {
            path: 'chobi',
            name: 'chobi',
            
            component: ChoBi,
            children: [
              {
                path: 'chobi1',
                name: 'ChoBi1',
                component: ChoBi1,
              },
              {
                path: 'tibi2',
                name: 'TiBi2',
                component: TiBi2,
              },
              {
                path: 'zhuangzhan3',
                name: 'ZhuangZhan3',
                component: ZhuangZhan3,
              },
             
            ],
          },
          {
            path: 'historyshoyi',
            name: 'historyshoyi',
            component: historyshoyi,
          },
          {
            path: 'bangzhu',
            name: 'bangzhu',
            component: bangzhu,
          },
          
          {
            path: 'fankui',
            name: 'fankui',
            component: fankui,
          },
         
          {
            path: 'fankuiadd',
            name: 'fankuiadd',
            component: fankuiadd,
          },
          {
            path: 'fanxiangqin',
            name: 'fanxiangqin',
            component: fanxiangqin,
          },
          
          {
            path: 'shandui',
            name: 'shandui',
            component: shandui,
          },
          {
            path: 'yaoqing',
            name: 'yaoqing',
            component: yaoqing,
          },
          {
            path: 'zhuzao',
            name: 'zhuzao',
            component: zhuzao,
          },
          {
            path: 'shequ',
            name: 'shequ',
            component: shequ,
          },
          {
            path: 'zhangdan',
            name: 'zhangdan',
            component: zhangdan,
          },
        ]
      },
   
    ],
  },
  {
    path: '/boardOfDirectors',
    name: 'boardOfDirectors',
    component: boardOfDirectors,
  },
  {
    path: '/VisualChart',
    name: 'VisualChart',
    component: VisualChart,
  },
 
  
  // {
  //   path: '/lianchuang',
  //   name: 'lianchuang',
  //   component: lianchuang,
  // }
  
];

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  // base: './',
  scrollBehavior (to, from, savedPosition) {
   
      return { x: 0, y: 0 }
    
  },
  
  routes,
});
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0);
})
export default router;
