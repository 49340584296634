<template>
    <div class="transferPage">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="check_account">
            <div class="check_account_box">游戏账户</div>
            <div class="check_account_logo">
                <img src="@/assets/imgsnowball/transfer_check.png" alt="">
            </div>
            <div class="check_account_box" style="border: 1px solid rgba(180, 135, 255, 0.46); background: transparent; color: rgba(255, 255, 255, 0.6);">资产账户</div>
        </div>
        <div class="transfer_box">
            <div class="transfer_box_title">数量</div>
            <div class="transfer_box_input">
                <input type="number" placeholder="请输入数量" />
                <div class="transfer_box_input_All">全部</div>
            </div>
            <div class="transfer_box_banlace">
                最多可划转：0.00
            </div>
            <div class="transfer_box_between">
                <div class="transfer_box_between_left">
                    应到数量
                </div>
                <div class="transfer_box_between_right">
                    0.00
                </div>
            </div>
            <van-button class="transfer_box_button">最多可划转</van-button>
        </div>
        <div class="title">账单</div>
        <div class="transfer_box">
            <div class="transfer_box_li">
               <div class="transfer_box_li_box">
                   时间
               </div>
               <div class="transfer_box_li_box">
                数量
               </div>
               <div class="transfer_box_li_box">
                   状态
               </div>
            </div>
            <!-- <div class="transfer_box_li">
               <div class="transfer_box_li_box" >
                2024.6.8  <br> 00:00:00
                
               </div>
               <div class="transfer_box_li_box">
                ＋000.0
               </div>
               <div class="transfer_box_li_box">
                待确定
               </div>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
        toprouter() {
            this.$router.go(-1);
        },
    },
}

</script>
<style scoped lang='less'>
.recruit_title {
    color: var(rgba(255, 255, 255, 1));
   padding-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.transferPage {
    padding: 20px;
    background: rgba(13, 8, 26, 1);
    min-height: 100vh;
}
.title{
    font-size: 18px;
font-weight: bold;
margin: 30px 0 12px 0;
color: #fff;
}
.transfer_box {
    margin: 22px 0 30px 0;
    padding: 22px 18px;
    border-radius: 30px;
    background: rgba(27, 31, 46, 1);
    .transfer_box_li:first-child{
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(58, 70, 92, 1);
    }
    .transfer_box_li{
        margin-bottom: 34px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-size: 14px;
font-weight: bold;
color: rgba(255, 255, 255, 0.6);
         &>div{
          width: calc(100%/3);
            display: flex;
            align-items: center;
            justify-content: center;
         }
         &>div:first-child{
             justify-content: left;
         }
         &>div:last-child{
             justify-content: right;
         }
    }
    .transfer_box_button{
        margin-top: 33px;
        width: 100%;
        height: 52px;
opacity: 1;
border-radius: 110px;
background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(204, 204, 204, 1);
font-size: 18px;
font-weight: 700;
letter-spacing: 0px;
line-height: 27px;
color: rgba(0, 0, 0, 1);
border: none;
    }
    .transfer_box_between{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
font-weight: bold;
        .transfer_box_between_left{
            color: rgba(255, 255, 255, 0.6);
        }
    .transfer_box_between_right{
        color: rgba(255, 255, 255, 1);
    }
    }

    .transfer_box_title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 16px;

    }
    .transfer_box_banlace{
        margin: 14px 0 29px 0;
        font-size: 12px;
font-weight: 500;
letter-spacing: 0px;
line-height: 16.8px;
color: rgba(255, 255, 255, 1);
opacity: 0.4;
    }

    .transfer_box_input {
        width: 100%;
        height: 52px;
        opacity: 1;
        border-radius: 87px;
        background: rgba(45, 58, 82, 1);
        display: flex;
        padding: 16px 30px;
        font-size: 14px;
        font-weight: 500;

        input {
            flex: 1;
            border: none;
            background: none;
            color: rgba(255, 255, 255, 0.6);
        }

        .transfer_box_input_All {
            border-left: 2px solid rgba(179, 136, 255, 1);
            padding-left: 19.5px;
            color: rgba(179, 136, 255, 1);

            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.check_account {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;

    color: rgba(0, 0, 0, 1);

    .check_account_box {
        width: 35%;
        height: 48px;
        opacity: 1;
        border-radius: 30px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .check_account_logo {
        width: 22px;
        height: 22px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>