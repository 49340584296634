const HOSTURLOBJECT = window.location
let configURL = {
	http_url:'',
	initveUrl:''
}

if(HOSTURLOBJECT.hostname == 'localhost'){
	//happydex.cc
	//happy.darw.club
	 configURL = {
		  http_url:"https://happydex.cc/api",
		  initveUrl:"https://happydex.cc"
	}
}else{
	configURL = {
		  http_url: HOSTURLOBJECT.origin +'/api',
		  initveUrl:HOSTURLOBJECT.origin
	}
}
console.log('configURL',HOSTURLOBJECT);

export default configURL