<template>
    <div class="gamemarket">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #fff" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="joinTitle">
            <span>{{ $t('lang.h147') }}</span>
            <span>{{ $t('lang.h148') }}</span>
            <img class="joinTitle_icon" src="@/assets/img/joinTitle_icon.png" alt="">
        </div>
        
        <div class="joinText">
            <span>{{ $t('lang.h149') }}</span>
        </div>
        <div class="joinButton" @click="smoothTo">{{ $t('lang.h150') }}</div>
        <img src="@/assets/img/join_bg.png" alt="" style="width: 100%;">

        <div class="ComingSoonTitle">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('欢迎体验') }}</span>
        </div>
        <div class="game_image" style="margin: 25px 0;">
            <img src="@/assets/img/gameImage/game1.png" alt="">
        </div>
        <div class="ComingSoonTitle" id="myElementId">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('lang.h151') }}</span>
        </div>
        <div class="gameList">
            <div class="gameList_item" v-for="(item, index) in gameList" :key="index">
                <img :src="item.image" alt="">
            </div>
        </div>

        <div class="game_box_video">
            <video src="@/assets/img/gameImage/启动引擎.mp4" controls="controls" class="game_video"></video>
            <div class="game_box_title">社交游戏</div>
            <div class="game_box_text">在元宇宙的世界里，社区经济将成为主流，社区的协作成为新的增长点，跟你的团队一起协作吧！在激烈、快节奏、混乱的战斗中脱颖而出，赢得财富宝箱，并可以购买升级，赢得最终胜利！</div>
        </div>
        <div class="ComingSoonTitle">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('更多游戏') }}</span>
        </div>

        <div class="joinText">
            <span>HappyDex游戏市场将保持开放，欢迎三方游戏合作上架，或共同研发。 很快更多的游戏会被引入，H5 、桌面设备、移动设备、PAD终端等，为您带来全新的链上娱乐体验····</span>
        </div>
        <div class="game_image" style="margin: 18px 0;" v-for="(item, index) in moreGameList" :key="index">
            <img :src="item.image" alt="">
        </div>

     
        <div class="ComingSoonTitle">
            <img src="@/assets/img/ComingSoonTitle_icon.png" class="ComingSoonTitle_icon" alt="">
            <span>{{ $t('lang.h152') }}</span>
        </div>
        <div class="partnerBox">
            <img src="@/assets/img/pathers_bg.png" alt="">
        </div>

        <div class="happydex_icon">
            <img src="@/assets/img/happydex_icon.png" alt="">
        </div>
        <div class="GoBuyBox">
            <div class="GoBuyBox_left">
                <div class="GoBuyBox_left_item" style="margin-bottom: 25px;">
                    <img src="@/assets/img/hap_icon.png" alt="">
                    <span>${{ banlace.HAP.usd_price }}</span>
                </div>
                <div class="GoBuyBox_left_item">
                    <img src="@/assets/img/goc_icon.png" style="width: 19px; height: 29px;" alt="">
                    <span>${{ banlace.GOC.usd_price }}</span>
                </div>
            </div>
            <div class="GoBuyBox_right" @click="goTo('Swap')">
                <span>{{ $t('lang.h153') }}</span>
                <div style="margin-left: 11px; transform: rotate(-90deg);">
                    <van-icon name="down" />
                </div>

            </div>
        </div>
        <div class="ecologyList">
            <div style="font-size: 20px; font-weight: bold; color: #fff;">{{ $t('lang.h154') }}</div>
            <div v-for="(item, index) in ecologyList" :key="index" @click="goTo(item.router)">
                {{ $t(item.name) }}
            </div>
        </div>
    </div>
</template>

<script>
import { happydex, nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    name: '',
    components: {

    },
    data() {
        return {
            gameList: [
                {
                    image: require('@/assets/img/game1.png')
                },
                {
                    image: require('@/assets/img/game2.png')
                },
                {
                    image: require('@/assets/img/game3.png')
                },
                {
                    image: require('@/assets/img/game4.png')
                },
                {
                    image: require('@/assets/img/game5.png')
                },
                {
                    image: require('@/assets/img/game6.png')
                },
                {
                    image: require('@/assets/img/game7.png')
                },
                {
                    image: require('@/assets/img/game8.png')
                }, {
                    image: require('@/assets/img/game9.png')
                }, {
                    image: require('@/assets/img/game10.png')
                }
            ],
            moreGameList:[
                {
                    image: require('@/assets/img/gameImage/game4.png')
                },
                {
                    image: require('@/assets/img/gameImage/game5.png')
                },
                {
                    image: require('@/assets/img/gameImage/game6.png')
                },
                {
                    image: require('@/assets/img/gameImage/game7.png')
                },
                {
                    image: require('@/assets/img/gameImage/game8.png')
                },
            ],
            ecologyList: [
                {
                    name: 'lang.swap88',
                    router: 'Swap',
                },
                {
                    name: 'lang.h2',
                    router: 'earn',
                },
                {
                    name: 'lang.h145',
                    router: 'NFTCenter',
                },
                {
                    name: 'lang.swap292',
                    router: 'iso',
                },
                {
                    name: 'lang.h5',
                    router: 'Invitation',
                }
            ],
            banlace: {
                HAP: {},
                GOC: {}
            }
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.getbanlace()
        },

        async getSign(){
            let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Game Encrypt Address'), this.account);
          happydex.gameencryptaddr({signature:sign},this.account).then(res=>{
             
          })
        },
        getbanlace() {
            nft.walletinfobykey({ token_key: 'HAPB' }, this.account).then(res => {
                if (res.code == 200) {
                    this.banlace.HAP = res.data
                }

            })
            nft.walletinfobykey({ token_key: 'GOC' }, this.account).then(res => {
                if (res.code == 200) {
                    this.banlace.GOC = res.data
                }
            })
        },
        goTo(router) {
            if (router != '') {
                this.$router.push({ name: router });
            } else {
                this.$toast('敬请期待');
            }
        },
        smoothTo() {
            var element = document.getElementById('myElementId');
            element.scrollIntoView({ behavior: "smooth" });
        },
        toprouter() {
            this.$router.go(-1);
        },
    },
}
</script>

<style lang="less" scoped>
.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 0px 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.game_image{
    width: 100%;
height: 160px;
img{
    width: 100%;
    height: 100%;
}
}

.gamemarket {
    background: rgba(13, 8, 26, 1);
    padding: 20px;
    padding-bottom: 147px;
}
.game_box_video{
    margin: 37px 0 18px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px;
    padding-bottom: 47px;
    opacity: 1;
border-radius: 30px;
background: rgba(27, 31, 46, 1);
    .game_video{
        border-radius: 30px;
        width: 100%;
        height: 171px;
        margin-bottom: 30px;
    }
    .game_box_title{
        font-size: 20px;
font-weight: bold;
letter-spacing: 2px;
line-height: 21.2px;
color: rgba(155, 157, 254, 1);
margin-bottom: 20px;
    }
    .game_box_text{
        font-size: 13px;
font-weight: 500;
letter-spacing: 0px;
line-height: 18.2px;
color: rgba(255, 255, 255, 1);
vertical-align: top;
    }
}

.ecologyList {
    margin-top: 26px;

    &>div {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 21px;
        color: rgba(201, 201, 201, 1);
        margin-bottom: 20px;
    }
}

.GoBuyBox {
    border-bottom: 1px solid rgba(27, 31, 46, 1);
    border-top: 1px solid rgba(27, 31, 46, 1);
    width: 100%;
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .GoBuyBox_right {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 27px;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 108px;
        height: 36px;
        opacity: 1;
        border-radius: 110px;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    }

    .GoBuyBox_left {
        .GoBuyBox_left_item {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 18.75px;
            color: rgba(165, 162, 171, 1);

            img {
                margin-right: 10px;
                width: 22px;
                height: 25px;
            }
        }
    }
}

.happydex_icon {
    width: 123px;
    height: 22px;
    margin-bottom: 20px;

    img {
        width: 100%;
        height: 100%;
    }
}

.partnerBox {
    padding: 12px 16px;
    margin-top: 20px;
    margin-bottom: 42px;
    width: 100%;
    height: 238px;
    opacity: 1;
    border-radius: 20px;
    background: rgba(27, 31, 46, 1);

    img {
        width: 100%;
        height: 100%;
    }
}

.gameList {
    margin-top: 39px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    .gameList_item {
        margin-bottom: 22px;
        width: 148.01px;
        height: 143.34px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.ComingSoonTitle {
    position: relative;
    width: 100%;
    text-align: center;

    .ComingSoonTitle_icon {
        width: 36px;
        height: 17px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 30px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%),
    rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.joinButton {
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 27px;
    color: rgba(0, 0, 0, 1);
    width: 218px;
    height: 46px;
    opacity: 1;
    border-radius: 110px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
}

.joinText {
    margin: 20px 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 19.6px;
    color: rgba(255, 255, 255, 1);
    text-align: justify;
    vertical-align: top;
}

.joinTitle {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 30px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .joinTitle_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 296.54px;
        height: 34.85px;
    }
}
</style>