<template>
  <div class="vote">
    <div class="title">{{ voteinfo.title }}</div>
    <div class="text" v-html="voteinfo.content">
     
    </div>
    <div class="voteTime">{{ $t('lang.h87') }}: {{formatDate(voteinfo.begin_time)}}至{{formatDate(voteinfo.end_time)}}</div>
    <div class="vote_boxs" :style="voteStatus == 0 ||voteStatus == 1?  'opacity: 0.6;':''">
      <div class="box" @click="partnervotedo('1')">
        <img src="@/assets/img/vote_icon1.png" class="img_abs" alt="" />
        <span> {{ $t('lang.h88') }}</span>
      </div>
      <div style="font-size: 18px; font-weight: bold">OR</div>
      <div class="box box_right" @click="partnervotedo('0')">
        <img src="@/assets/img/vote_icon2.png" class="img_abs2" alt="" />
        <span>{{ $t('lang.h89') }}</span>
      </div>
    </div>
    <div class="box_line" style="margin-bottom: 12px; margin-top: 26px;">
      <div id="box_line_bg1" class="box_line_bg" :style="`background: rgba(52, 252, 252, 1) ; width:${votesummary.agreeRate}%;`"></div>
      <div class="box_line_left">{{ $t('lang.h88') }}</div>
      <div class="box_line_right">
        <span>{{ votesummary.agree }}</span>
        <div class="box_line_right_line"></div>
        <span style="color: var(--text-color);">{{votesummary.agreeRate}}%</span>
      </div>
    </div>
    <div class="box_line" style="margin-bottom: 27px;">
      <div id="box_line_bg2" class="box_line_bg" :style="`background: rgba(182, 133, 255, 1) ; width:${votesummary.disagreeRate}%;`"></div>
      <div class="box_line_left">{{ $t('lang.h89') }}</div>
      <div class="box_line_right">
        <span>{{ votesummary.disagree }}</span>
        <div class="box_line_right_line"></div>
        <span style="color: var(--text-color);">{{votesummary.disagreeRate}}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { happydex } from '@/api/index';
import Web3 from 'web3';
import { mapState } from 'vuex';
export default {
  data() {
    return {
        num:20,
        votesummary:{
          agree:0,
          disagree:0,
          sum:0,
          agreeRate:0,
          disagreeRate:0,
        },
        voteinfo:{
          begin_time:'',
          end_time:'',
          title:'',
          content:'',
        },
        voteStatus:0,
        StatusMsg:'',
    };
  },
  computed:{
    ...mapState(['account', 'lang','ColorCheckData1']),
  },
  watch: {
    account() {
      this.init();
      // this.finished = false;
    },
    lang() {
      this.init();
    },
  },
  components: {},
  created(){
        this.init()
  },
  mounted() {
      
  },
  methods: {
       init(){
           this.partnervotestatus()
           this.partnervoteinfo()
           this.partnervotesummary()
       },
       formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1，然后补0
  const day = date.getDate().toString().padStart(2, '0'); // 获取日期并补0
  return `${year}年${month}月${day}日`;
},
       partnervotestatus(){
            happydex.partnervotestatus(this.account).then(res=>{
                this.voteStatus  = res.data
                this.StatusMsg = res.msg
            })
       },
       partnervoteinfo(){
           happydex.partnervoteinfo(this.account).then(res=>{
                  this.voteinfo = res.data
           })
       },
       formatDecimal(num) {
        const integerPart = Math.floor(num); // 取整数部分
  const decimalPart = Math.floor((num - integerPart) * 100); // 取小数点后两位并向下取整
  return num.toFixed(2)
},
       partnervotesummary(){
              happydex.partnervotesummary(this.account).then(res=>{
                         this.votesummary = res.data
                         this.votesummary.sum = this.votesummary.agree + this.votesummary.disagree
                         this.votesummary.agreeRate = this.formatDecimal(this.votesummary.agree / this.votesummary.sum)*100
                         this.votesummary.disagreeRate = this.formatDecimal(this.votesummary.disagree / this.votesummary.sum)*100
              })
       },
      async partnervotedo(index){
        if(this.voteStatus == -1) return this.$toast(this.StatusMsg);
        if(this.voteStatus == 0|| this.voteStatus == 1) return this.$toast(this.$t('lang.h90'));
        
        let web3 = new Web3(window.ethereum);
      const sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Partner Vote'), this.account);
         let data ={
          signature : sign,
          view_point:index
         }
         happydex.partnervotedo(data,this.account).then(res=>{
                if(res.code == 200){
                  this.init()
                }else{
                  this.$toast(res.msg)
                }
         })
       }
     
  },
};
</script>
<style scoped lang='less'>
.vote {
  width: 100%;
  text-align: center;
  .title {
    background: -webkit-linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .text {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 22px;
    color: var(--text-color);
  }
  .voteTime {
    font-size: 12px;
    font-weight: 500;
    background: -webkit-linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%), rgba(255, 255, 255, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 17px 0 40px 0;
  }
}
.box_line {
    padding: 14px 5px 14px 16px;
  position: relative;
  width: 100%;
  height: 47px;
  overflow: hidden;
  border-radius: 8px;

  border: 1px solid rgba(52, 252, 252, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box_line_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: inherit;
    border-radius: 8px;
    opacity: 0.2;
    transition: .5s ease;
  }
  .box_line_left{
    z-index: 99;

    font-size: 14px;
font-weight: 700;
   color: var(--text-color);
  }
  .box_line_right{
    z-index: 99;

    display: flex;
    align-items: center;
    font-size: 14px;
font-weight: 700;
    .box_line_right_line{
        margin: 0 16px;
        width: 1px;
        height: 12.5px;
        
        background: rgba(96, 99, 109, 1);
    }
  }
}
.vote_boxs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box {
    position: relative;
    width: 133.29px;
    height: 43px;
    opacity: 1;
    border-radius: 21.5px 0px 21.5px 21.5px;
    background: rgba(52, 252, 252, 1);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: rgba(27, 31, 46, 1);
    .img_abs {
      width: 47px;
      height: 47px;
      position: absolute;
      left: -10px;
      top: -50%;
    }
  }
  .box_right {
    background: rgba(182, 133, 255, 1);
    color: rgba(255, 255, 255, 1);
    .img_abs2 {
      right: 5px !important;
      top: -50%;
      width: 47px;
      height: 47px;
      position: absolute;
    }
  }
}
</style>