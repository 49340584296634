<template>
  <div class="iso">
    <div class="i_main" :style="`background: url(${ColorCheckData1 == 'black' ? bglist.image : bglist.image1}) no-repeat;`">
      <div class="m_title">{{ $t('lang.h47') }}</div>
      <div class="m_text">{{ $t('lang.h48') }}</div>
      <div class="buttons">
        <div class="b_b1">{{ $t('lang.swap356') }}</div>
        <div class="b_b1">{{ $t('lang.swap357') }}</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="(item, index) of switchlist" :key="index" @click="shiswitch(index)" :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(182, 133, 255, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>
      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <div class="over" v-show="remenshow"></div>
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.swap364')}`" class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <!-- <div class="meiyou">
        <img src="@/assets/img/zanwu.png" alt="" v-if="ColorCheckData1 == 'black'" />
        <img src="@/assets/img/zanwu1.png" alt="" v-else />
        <span>{{ $t('lang.swap365') }}</span>
      </div> -->
      <template>
        <div class="lon_list" style="margin-bottom: 26px" v-for="(item, index) in poollist" :key="index">
          <div class="lon_title">
            <div class="lon_title_left" style="display: flex; align-items: center">
              <div class="title_img">
                <img :src="item.logo" alt="" />
              </div>
              <span>{{ item.token }}</span>
            </div>
            <div class="lon_title_right">
              <span v-if="item.pledge_amount > 0">进行中</span>
              <span v-else>未开启</span>
            </div>
          </div>
          <div class="lon_line" style="margin-bottom: 22px">
            <div class="line_left">
              <span>已赚取</span>
              <span style="font-size: 24px">{{ item.reward_amount }}</span>
            </div>
            <div class="line_right_button button_active" @click="pledgepoolclaim(item)" v-if="item.claim_status == '1'">领取</div>
            <div class="line_right_button button_active" style="opacity: 0.4" v-else>不可领取</div>
          </div>
          <div class="lon_line">
            <div class="line_left">
              <span>收益中</span>
              <span>{{ item.pledge_amount  }}</span>
            </div>
            <div class="line_right_buttons">
              <div style="margin-right: 16px" @click="popupshowFun2(item)">-</div>
              <div @click="popupshowFun(item)">+</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-popup v-model="popupshow" round position="bottom">
      <div class="popup">
        <div class="popup_title">账户余额：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ banlace }}</div>
        <div class="popup_title">参与数量：</div>
        <div class="popup_box">
          <input type="number" v-model="joinInput" />
          <div class="popup_box_right" @click="fullInput">全部</div>
        </div>
        <div class="poup_reamd">单次可新增数量为: {{ pledegejoinInfo.min_pledge }} {{ pledegejoinInfo.token }} 起</div>
        <div class="popup_button" @click="pledgepooldo">确定</div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow2" round position="bottom">
      <div class="popup">
        <div class="popup_title">收益中：</div>
        <div class="popup_box" style="margin-bottom: 20px">{{ pledegeredeemInfo.pledge_amount }}</div>
        <div class="popup_title">可赎回：{{ pledegeredeemInfo.pledge_amount }}</div>
        <div class="popup_box">
          <input type="number" v-model="redeemInput" />
          <div class="popup_box_right" @click="fullredeemInputInput">全部</div>
        </div>
        <div class="poup_reamd">单次可新增数量为:{{ pledegeredeemInfo.min_pledge }} {{ pledegeredeemInfo.token }} 起</div>
        <div class="popup_button" @click="pledgepoolredeem">确定</div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow3" round closeable>
      <div class="status_popup">
        <img src="@/assets/img/status_success.png" alt="" />
        <span>{{ toastText }}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { pledge, moneymask } from '@/api';
import basicUrl from '@/config/configUrl/configUrl';
import Web3 from 'web3';
export default {
  name: 'iso',
  components: {},
  data() {
    return {
      popupshow: false,
      popupshow2: false,
      popupshow3: false,
      regionIndex: 0,
      toInput: '',
      checked: false,
      remenshow: false,
      bglist: {
        image: require('@/assets/img/earnmoneybg.png'),
        image1: require('@/assets/img/earnmoneybg1.png'),
      },
      switchlist: ['lang.swap358', 'lang.swap359'],
      poollist: [],
      pledegejoinInfo: {},
      banlace: 0,
      pledegeredeemInfo: {},
      joinInput: 0,
      redeemInput:0,
      toastText:'操作成功'
    };
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData1']),
  },
  watch: {
    account() {
      this.init();
    },
    lang() {},
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.account) {
       
        this.pledgepoollist();
      }
    },
    getwalletgetinfo(id) {
      moneymask.getwalletgetinfo({ token_id: id }, this.account).then(res => {
        this.banlace = res.data.usable;
      });
    },

    pledgepoollist() {
      pledge.pledgepoollist(this.account).then(res => {
        let list = res.data;
        for (let item of list) {
          item.logo = basicUrl.http_url + item.logo;
        }
        this.poollist = list;
      });
    },
    fullInput() {
      this.joinInput = parseFloat(this.banlace);
    },
    fullredeemInputInput(){
          this.redeemInput = parseFloat(this.pledegeredeemInfo.pledge_amount);
    },
    shiswitch(index) {
      this.regionIndex = index;
    },
    remeshowfun() {
      this.remenshow = !this.remenshow;
    },
    popupshowFun(item) {
      this.pledegejoinInfo = item;
      this.getwalletgetinfo(item.token_id);
      this.popupshow = !this.popupshow;
    },
    popupshowFun2(item) {
      this.pledegeredeemInfo = item;
      this.popupshow2 = !this.popupshow2;
    },
    popupshowFun3() {
      this.popupshow3 = !this.popupshow3;
    },
    async pledgepooldo(){
      let web3 = new Web3(window.ethereum);
       let sign = null
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Do'), this.account);
      } catch (error) {
            this.$toast('签名失败')
            return;
      }
      let data = {
        pool_id: this.pledegejoinInfo.id,
        amount: parseFloat(this.joinInput),
        signature:sign
      };
        pledge.pledgepooldo(data,this.account).then(res=>{
          this.popupshow = false
          if(res.code == 200){
                   this.init()
                  
                   this.toastText = '参与成功'
                   this.popupshow3 = true
              }else{
                this.$toast(res.msg)
              }
        })
    },
    async pledgepoolredeem(){
      let web3 = new Web3(window.ethereum);
       let sign = null
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Redeem'), this.account);
      } catch (error) {
            this.$toast('签名失败')
            return;
      }
      let data = {
        pool_id: this.pledegeredeemInfo.id,
        amount: parseFloat(this.redeemInput),
        signature:sign
      };
        pledge.pledgepoolredeem(data,this.account).then(res=>{
          this.popupshow2 = false
          if(res.code == 200){
                   this.init()
                  
                   this.toastText = '赎回成功'
                   this.popupshow3 = true
              }else{
                this.$toast(res.msg)
              }
        })
    },
    async pledgepoolclaim(item) {
      if (item.reward_amount == 0) {
        this.$toast('暂无可领取');
        return;
      }
      let web3 = new Web3(window.ethereum);
       let sign = null
      try {
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('Pledge Reward Claim'), this.account);
      } catch (error) {
            this.$toast('签名失败')
            return;
      }
       
      let data = {
        pool_id: item.id,
        amount: item.reward_amount,
        signature:sign
      };
      console.log(data,'data');
      pledge.pledgepoolclaim(data,this.account).then(res=>{
              if(res.code == 200){
                   this.init()
                   this.toastText = '领取成功'
                   this.popupshow3 = true
              }else{
                this.$toast(res.msg)
              }

      })
    },
  },
};
</script>

<style lang="less" scoped>
.iso {
  background: var(--bg-color);
}

.i_main {
  text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size: contain !important;
  background-position: 0 130px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var(--text-color);
    text-align: center;
    margin-top: 40px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158, 156, 163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: 'HarmonyOSSansSC';
  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {
      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.12);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
      color: rgba(158, 156, 163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #000;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var(--text-color);
      }
    }
  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var(--text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;
      }
    }
  }
}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var(--line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';

  .m_icon {
    transform: rotate(90deg);
    color: var(--text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var(--text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: var(--line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;
  
  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var(--box-bg-color);
  position: relative;
  padding: 28px 20px;
  

  .lon_list {
    padding: 19px 15px;
    width: 100%;

    opacity: 1;
    border-radius: 16px;

    border: 1px solid rgba(180, 135, 255, 0.46);

    .lon_title {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: var(--text-color);
      .lon_title_right {
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        opacity: 1;
        border-radius: 3px;
        color: rgba(177, 138, 255, 1);
        border: 1px solid rgba(177, 138, 255, 1);
      }

      .title_img {
        margin-right: 5px;

        img {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin-right: 3px;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 8px;
      background: var(--line-bg-color);
      display: flex;
      align-items: end;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 700;
        color: rgba(153, 153, 153, 1);
      }
      .line_right_buttons {
        display: flex;
        & > div {
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 31px;
          width: 34px;
          height: 31px;
          opacity: 1;
          border-radius: 8px;
          background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
        }
      }

      .line_right_button {
        width: 67px;
        height: 31px;
        opacity: 1;
        border-radius: 8px;
        background: var(--bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var(--text-color);
      }
      .button_active {
        color: #000;
        background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
      }
    }
    .lon_line2 {
      width: 100%;
      height: 109px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(240, 245, 254, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 18px;
      .line2_title {
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(0, 0, 0, 1);
      }
      .line2_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(173, 184, 204, 1);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: var(--text-color);
      }
    }
    .lon_line3 {
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line3_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
      .line3_right {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
      height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}
.popup {
  padding: 20px;
  .popup_title {
    font-size: 16px;
    font-weight: 700;
    color: var(--bg-color);
    margin-bottom: 8px;
  }
  .popup_box {
    padding: 16px;
    opacity: 1;
    border-radius: 7px;

    border: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
      border: none;
      background: none;
    }
    .popup_box_right {
      font-size: 14px;
      color: rgba(127, 183, 254, 1);
      padding-left: 23px;
      border-left: 1px solid rgba(230, 230, 230, 1);
    }
  }
  .poup_reamd {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(153, 153, 153, 1);
    margin-top: 8px;
  }
  .popup_button {
    margin-top: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    opacity: 1;
    border-radius: 110px;
    background: linear-gradient(138.02deg, rgba(48, 255, 252, 1) 0%, rgba(182, 133, 255, 1) 100%);
    font-size: 18px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
  }
}
.status_popup {
  width: 335px;
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 147px;
    height: 88px;
  }
}
</style>