const base = [
  {
    "name": "BNB Token",
    "symbol": "BNB",
    "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": require('@/assets/img/BNB.png')
  },
  {
    "name": "Tether USD",
    "symbol": "USDT",
    "address": "0x55d398326f99059fF775485246999027B3197955",
    "chainId": 56,
    "decimals": 18,
    "logoUrl": require('@/assets/img/logo/USDT.png')
  },
  {
    "chainId": 56,
    "address": "0xb61e1493cb2cbbf2e3e89872c6095aa000d5db32",
    "name": "HAP",
    "symbol": "HAP",
    "decimals": 18,
    "logoURI": require('@/assets/tokenlogo/HAP.png')
  },
  
];

export function getAllPair(curA, curB) {
  let lpPair = [];
  for (let i = 0; i < base.length; i++) {
    if (base[i].address != curA.address) {
      lpPair.push({ toCur: base[i], fromCur: curA });
    }

    if (base[i].address != curB.address) {
      lpPair.push({ toCur: curB, fromCur: base[i] });
    }

    lpPair.push({ toCur: curB, fromCur: curA });
  }
  return lpPair;
}
